import(/* webpackMode: "eager" */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/src/app/(site)/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/src/components/footer/Footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["AmplitudeProvider"] */ "/home/runner/work/detsombetyrnoe/detsombetyrnoe/src/contexts/amplitude-context.js");
